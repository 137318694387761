import { CSSProperties, useState } from "react";


const SplashPage = () => {

  return (
    <div className="background-image" >
      <div className="background-gradient-dark">
      </div>
    </div>
  );
};

export default SplashPage;
