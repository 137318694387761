import { useContext } from "react";

import { Context } from "./functions/context";
import { useState } from "react";

import AuthPage from "./AuthPage";
import ChatsPage from "./ChatsPage";
import { PersonObject } from "react-chat-engine-advanced";

import "./app.css";
import axios from "axios";
import { privateKey,projectId } from "./functions/constants";
import SplashPage from "./splash/splash";

var once = false;
function createSenderAccount(callback:any,name:string,email:string,password:string){
  axios.put("https://api.chatengine.io/users/",
    {"username": email,"secret": password,"first_name":name},{
      headers:{"PRIVATE-KEY": privateKey}
    }).then((r) => {
      if (r.status === 200) {
        const user: PersonObject = {
          first_name: r.data.first_name,
          last_name: r.data.last_name,
          email: email,
          username: email,
          secret: password,
          avatar: r.data.avatar,
          custom_json: {},
          is_online: true,
        };
        callback(user);
      }
    })
    .catch((e) => {
      console.error(e);
      window.open("https://dentswift.com","_self")
    });
}

function createReceiverAccount (callback:any,name:string,email:string,password:string){
  axios.put("https://api.chatengine.io/users/",
    {"username": email,"secret": password,"first_name":name},{
      headers:{"PRIVATE-KEY": privateKey}
    }).then((r) => {
      if (r.status === 200) {
        const user: PersonObject = {
          first_name: r.data.first_name,
          last_name: r.data.last_name,
          email: email,
          username: email,
          secret: password,
          avatar: r.data.avatar,
          custom_json: {},
          is_online: true,
        };
        callback(user);
      }
    })
    .catch((e) => {
      console.error(e);
      window.open("https://dentswift.com","_self")
    });
}

function createSenderReceiverChat (callback:any,sender:PersonObject,receiver:PersonObject){
  axios.put("https://api.chatengine.io/chats/",
  {"usernames": [sender?.email,receiver?.email],"is_direct_chat":true},{
    headers:{"Project-ID": projectId,"User-Name":sender?.email??"","User-Secret":sender?.secret??""}
  }).then((r) => {
    if (r.status === 200) {
      callback();
    }
  }).catch((e) => {
    console.error(e);
    window.open("https://dentswift.com","_self")
  });
}

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const url = new URL(document.baseURI);
  var sender,receiver;
  var skipReceiver:boolean;
  
  try{
    let searchParams = new URLSearchParams(url.search.substring(1));
    sender = JSON.parse(atob(searchParams.get("sen")??""));
    skipReceiver = searchParams.get("skip")=="true";
    if(!skipReceiver){
      try{
      receiver = JSON.parse(atob(searchParams.get("rec")??""));
      }catch(e){
        skipReceiver = true;
      }
    }
  }catch(e){
    window.open("https://dentswift.com","_self")
  }

  const senderName = sender.name??"";
  const senderEmail = sender.user??"";
  const senderPassword = sender.password??"";

  var receiverName="",receiverEmail="",receiverPassword="";
  try{
  receiverName = receiver.name??"";
  receiverEmail = receiver.user??"";
  receiverPassword = receiver.password??"";
  }catch(e){}

  const { setUser,setReceive } = useContext(Context);

if(!loggedIn&&!once){

  once = true;
    createSenderAccount((sender:PersonObject)=>{
      setUser(sender);
      if(skipReceiver){
        setLoggedIn(true)
        return;
      }
      createReceiverAccount((receiver:PersonObject)=>{
        setReceive(receiver);
        createSenderReceiverChat(()=>{setLoggedIn(true)},sender,receiver)
      },receiverName,receiverEmail,receiverPassword);
    },senderName,senderEmail,senderPassword);
}
    if(loggedIn){
      return <ChatsPage />
    }else{
      return <SplashPage />
    }
}

export default App;